html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video,
select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  line-height: 1;
}
a:focus,
a:hover,
a:active,
button {
  outline: none;
  text-decoration: none;
}
a {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}
html {
  height: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
body {
  font-size: 14px;
  height: 100%;
  
}
.clear {
  clear: both;
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999999;
}
.table-content {
  display: table;
  width: 100%;
  height: 100vh;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
  padding-top: 70px;
}
.noscroll {
  overflow: hidden;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
/* Header styling start */
header.q-header {
  background-color: transparent;
  position: absolute !important;
}
header.q-header .q-layout__shadow:after {
  box-shadow: unset;
}
header .q-toolbar.row.no-wrap.items-center {
  justify-content: space-between;
}

/* navbar styling start*/
.navbar {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  z-index: 1;
}
/*Logo styling start*/
.logo {
  position: fixed;
  top: 18px;
  left: 60px;
}
.logo img {
  width: 100px;
  cursor: pointer;
}
/* Menu styling start */
.site-menu {
  position: fixed;
  top: 18px;
  right: 60px;
}
.bars {
  width: 40px;
  height: 40px;
  margin-right: 9px;
  border-radius: 50px;
  background: rgba(193, 216, 46, 0.7);
  font-size: 20px;
  cursor: pointer;
  margin-top: 0px;
  position: relative;
  line-height: 40px;
  text-align: center;
  color: #ffff;
}
.close {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: rgba(193, 216, 46, 0.7);
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  top: 18px;
  right: 70px;
  line-height: 40px;
  text-align: center;
  color: #ffff;
}


.site-menu nav {
  position: fixed;
  top: 0px;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: 1s ease-in-out;
}
.site-menu nav.show {
  visibility: visible;
  opacity: 1;
  
}

.site-menu nav[show="true"] {
  visibility: visible;
  opacity: 1;
}
.site-menu nav[show="true"]::before {
  width: 102%;
  height: 102%;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  
}
.site-menu nav ul {
  list-style: none;
  position: relative;
  z-index: 567657;
}
.site-menu nav::before {
  content: "";
  position: absolute;
  right: -7px;
  width: 50px;
  top: -6px;
  height: 50px;
  border-radius: 100%;
  background: #b1d236;
  border-top-right-radius: 0;
  transition: 1s ease-in-out;
  
}
.site-menu nav.show::before {
  width: 102%;
  height: 102%;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.anchor {
  text-decoration: none;
  margin: 8px 0px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

/* Home Page styling  */
.banner {
  background: url("../public/assets/banner-back.png");
  min-height: 100vh;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  padding: 0px 100px;
  padding-top: 40px;
  position: relative;
  cursor: pointer;
}
.banner-boxes {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 96vh;
}
.banner-content {
  width: 50%;
  text-align: right;
}
.bannercontent {
  display: flex;
  align-items: center;
}
.banner-content .we {
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  font-size: 140px;
  font-family: "Lato", sans-serif;
}
.banner-content .create {
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  font-size: 80px;
  font-weight: bold;
  margin-top: 40px;
  font-family: "Lato", sans-serif;
}
.banner-content .awesome {
  display: flex;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  font-size: 30px;
  font-family: "Lato", sans-serif;
}
.banner_btn {
  padding: 8px 37px;
  border: 1px solid #fff;
  background: none;
  color: #fff;
  text-decoration: none;
  border-radius: 22px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}
.banner_btn:hover {
  color: #6bb325;
  font-weight: 400;
  background-color: #fff;
  font-weight: 400;
}
.banner-content h2 span {
  font-size: 200px;
}
.banner-content h2 div {
  font-size: 126px;
}
.banner-content h2 div p {
  font-size: 42px;
}

.banner-boxes.are .banner-content router-btn {
  display: none;
}
.banner-content.develop h2 div {
  font-size: 80px;
}
.banner-boxes.build .banner-content {
  width: 40%;
}
.banner-boxes.build .banner-image {
  padding-left: 0;
  width: 60%;
}
.banner-image {
  width: 50%;
  padding-left: 100px;
  text-align: center;
}
.banner-image img {
  max-width: 100%;
}
.banner-image router-btn {
  width: 320px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 50px;
  font-size: 23px;
  color: #fff;
  text-decoration: none;
  margin: 0px auto;
  margin-top: 15px;
  cursor: pointer;
}
.banner-image router-btn {
  display: none;
}
.banner-boxes.are .banner-image router-btn {
  display: flex;
}
.btn-banner {
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  padding: 15px 49px 16px;
  border-radius: 50px;
  font-size: 25px;
  display: inline-block;
  transition: 0.3s linear;
  cursor: pointer;
}
.btn-banner:hover {
  background: #fff;
  color: #6ab65a;
}

@media (max-width: 600px) {
  .banner {
    height: 72vh;
    max-height: 100vh;
  }
  .banner-img1 {
    margin-bottom: 60px;
  }
  .banner-content .we {
    display: flex;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    font-size: 90px;
  }
  .banner-content .create {
    display: flex;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    font-size: 45px;
    font-weight: bold;
    margin-top: 40px;
  }
  .banner-content .awesome {
    display: flex;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    font-size: 18px;
  }
  /* Menu styling start */
  .logo {
    position: fixed;
    top: 18px;
    left: 30px;
  }
  .site-menu {
    position: fixed;
    top: 18px;
    right: 30px;
  }
  .close {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: rgba(193, 216, 46, 0.7);
    font-size: 20px;
    cursor: pointer;
    position: fixed;
    top: 18px;
    right: 40px;
    line-height: 40px;
    text-align: center;
    color: #ffff;
  }
}
