/* wordpress portfolio */

.carousel img.slidearrow{
    width: auto;
}
.wordpressportfolio {
    max-width:90%;
  width: 100%;
  height: 100vh;
  margin: 2% auto 0;
}
.service_img_ui_ux {
  border: 1px solid #fff;
  padding: 50px;
  border-radius: 50%;
  width: 200px;
  max-width: 200px;
  height: 200px;
  max-height: 200px;
}
.ui_ux_top {
  display: flex;
  align-items: center;
  margin: auto 100px;
}
.ui_ux_left {
  display: flex;
  align-items: center;
}
.ui_ux_left p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  margin-top: 30px;
}
.ui_ux_left h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  margin-top: 30px;
  margin-right: -50px;
}
 .ui_c_btn {
  display: block;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 250px;
  border-radius: 50px;
  background-color: #027fa8;
  text-align: center;
  margin-left: 100px;
  cursor: pointer;
  transition: 0.4s;
  margin-left: 90px;
  font-family: 'Raleway', sans-serif;
} 
.ui_c_btn:hover {
  background-color: #0990bd;
}
.internal_scrolling{
  width: 100%;
  height: 82vh;
  overflow-y: scroll;
}
.ui_ux_w_main .internal_scrolling::-webkit-scrollbar {
  width: 5px;
  margin:10px;
  }
  
  .ui_ux_w_main .internal_scrolling::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px
  }
  
  .ui_ux_w_main .internal_scrolling::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #525861; 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  }
  .ui_ux_w_main .internal_scrolling::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255,0,0,0.4); 
}
.ui_ux_w_main {
  margin: 41px 0 0 230px;
  display: flex;
  width: 70%;
}
.ui_ux_w__img {
  width: 10%;
  height: 70vh;
}
.ui_ux_right_info {
  width: 50%;
  margin: 50px 0 0 40px;
}
.ui_ux_right_info h1 {
  color: #fff;
  font-size: 45px;
  font-weight: 400;
  text-align: left;
  font-family: 'Raleway', sans-serif;
}

.ui_ux_right_info p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: left;
  font-family: 'Raleway', sans-serif;

}
.view_more_btn_ui_ux {
  display: block;
  margin: 0 5px;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  border: 1px solid #fff;
  width: 200px;
  border-radius: 50px;
  text-align: center;
  font-family: 'Raleway', sans-serif
}
.view_more_btn_ui_ux:hover {
  color: #03a5db;
  background: #fff;
}

.c_more_btn_ui_ux {
  display: block;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 200px;
  border-radius: 50px;
  background-color: #027fa8;
  cursor: pointer;
  font-family: 'Raleway', sans-serif

}
.w_icon_ui_ux {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}
.w_icon_ui_ux:hover {
  background-color: #027fa8;
  border: 2px solid #027fa8;
}
.w_icon_ui_ux2 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}
.w_icon_ui_ux2:hover {
  background-color: #027fa8;
  border: 2px solid #027fa8;
}
.w_icon_ui_ux3 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}
.w_icon_ui_ux3:hover {
  background-color: #027fa8;
  border: 2px solid #027fa8;
}
.w_icon_ui_ux4 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}
.w_icon_ui_ux4:hover {
  background-color: #027fa8;
  border: 2px solid #027fa8;
}
.w_icon_ui_ux5 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}
.w_icon_ui_ux5:hover {
  background-color: #027fa8;
  border: 2px solid #027fa8;
}
.w_icon_ui_ux6 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}
.w_icon_ui_ux6:hover {
  background-color: #027fa8;
  border: 2px solid #027fa8;
}
.service-detail_ui_ux {
  width: 50%;
  position: relative;
}
/* first block */
.w_icon_ui_ux::before,
.w_icon_ui_ux::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 9%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon_ui_ux::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #03a5db;
  width: 300px;
  width: max-content;
  padding: 8px;
  font-family: 'Raleway', sans-serif;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_ui_ux:hover::before,
.w_img:hover::after {
  --scale: 1;
}
.w_icon_ui_ux::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* second block */
.w_icon_ui_ux2::before,
.w_icon_ui_ux2::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 24%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon_ui_ux2::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #03a5db;
  width: 300px;
  width: max-content;
  font-family: 'Raleway', sans-serif;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_ui_ux2:hover::before,
.w_img2:hover::after {
  --scale: 1;
}
.w_icon_ui_ux2::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* third block */
.w_icon_ui_ux3::before,
.w_icon_ui_ux3::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 40%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon_ui_ux3::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #03a5db;
  font-family: 'Raleway', sans-serif;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_ui_ux3:hover::before,
.w_img3:hover::after {
  --scale: 1;
}
.w_icon_ui_ux3::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* forth block */
.w_icon_ui_ux4::before,
.w_icon_ui_ux4::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 57%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon_ui_ux4::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #03a5db;
  width: 300px;
  font-family: 'Raleway', sans-serif;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_ui_ux4:hover::before,
.w_img4:hover::after {
  --scale: 1;
}
.w_icon_ui_ux4::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

/* fifth block */
.w_icon_ui_ux5::before,
.w_icon_ui_ux5::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 75%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon_ui_ux5::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #03a5db;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_ui_ux5:hover::before,
.w_img5:hover::after {
  --scale: 1;
}
.w_icon_ui_ux5::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* sixth block */
.w_icon_ui_ux6::before,
.w_icon_ui_ux6::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 90%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon_ui_ux6::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #03a5db;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  font-family: 'Raleway', sans-serif;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_ui_ux6:hover::before,
.w_img6:hover::after {
  --scale: 1;
}
.w_icon_ui_ux6::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}









@media (max-width: 600px) {
  .internal_scrolling{
    width: 136%;
    height: 75vh;
    overflow: hidden;
    margin-left: -220px;
  }
  .ui_ux_top {
    display: block;
    align-items: center;
    margin: auto 100px;
    margin-top: -30px;
  }
  .view_more_btn_ui_ux {
    margin-bottom: 10px;
    width: 100%;
  }
  .service-detail_ui_ux {
    text-align: center;
  }
  .c_more_btn_ui_ux {
    margin-bottom: 10px;
    width: 100%;
  }
  .service_img_ui_ux {
    margin: 90px 100px;
  }

  .ui_ux_left {
    margin-top: 50px;
  }
  .ui_ux_left p {
    font-size: 24px;
    font-weight: 300;
    margin-top: 50px;
  }
  .ui_ux_left h2 {
    font-size: 25px;
    font-weight: 900;
  }
  .ui_ux_c_btn {
  display: block;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 250px;
  border-radius: 50px;
  background-color: #167a3c;
  text-align: center;
  margin-left: 100px;
  cursor: pointer;
  transition: 0.4s;
  margin-left: 90px;
  font-family: 'Raleway', sans-serif;
  }
  .ui_ux_w_main {
    display: block;
    width: 90%;
  }
  .ui_ux_w__img {
    height: 81vh;
    margin-left: -365px;
  }
  .ui_ux_right_info {
    width: 100%;
    margin-left: -190px;
    margin-bottom: 100px;
  }
  .ui_ux_right_info h1 {
    color: #fff;
    text-align: justify;
    font-size: 60px;
  }

  .ui_ux_right_info p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 50px;
    text-align: justify;
  }
  .ui_ux_right_info span {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-align: justify;
  }
}
.service_img_ux_ui {
  border: 1px solid #fff;
  padding: 50px;
  border-radius: 50%;
  width: 200px;
  max-width: 200px;
  height: 200px;
  max-height: 200px;
  margin: 50px auto;
}
.ui_ux_content {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  margin: auto 200px;
}
.arrow_btn{
  position: absolute;
  top: 20px;
  right: 30px;
  
}
.arrow_btn i{
  font-size: 23px;
  color: #fff;
  cursor: pointer;
}



.ui_ux_img {
  border: 1px solid #fff;
  padding: 50px;
  border-radius: 50%;
  width: 200px;
  max-width: 200px;
  height: 200px;
  max-height: 200px;
}

.ui_ux_detail {
  width: 50%;
  position: relative;
}

.ui_ux_detail h2 {
  color: #fff;
  font-size: 37px;
  margin-left: 30px;
  text-align: left;
}
.ui_ux_detail p {
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 300;
  margin-left: 30px;
  text-align: justify;
}
.ui_ux_actions {
  display: flex;
  align-items: center;
  margin: 0 25px;
}
.ui_ux_view_more_btn {
  display: block;
  margin: 0 5px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  border: 1px solid #fff;
  width: 230px;
  border-radius: 50px;
}
.ui_ux_view_more_btn:hover {
  background-color: #fff;
  color: #03a5db;
}
.ui_ux_c_more_btn {
  display: block;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 350px;
  border-radius: 50px;
  background-color: #027fa8;
}
.ui_ux_c_more_btn:hover {
  background: #078bb8cc;
}
@media (max-width: 600px) {
  .ui_ux_content {
    width: 100%;
    height: 80vh;
    margin: auto -0px;
    display: block;
  }
  .ui_ux_img {
    width: 50%;
    margin-bottom: 30px;
    margin: auto 90px;
  }
  .ui_ux_detail {
    width: 90%;
    margin-top: 30px;
  }
  .ui_ux_detail h2 {
    font-size: 20px;
    font-weight: 600;
  }
  .ui_ux_detail p {
    font-size: 16px;
  }
  .ui_ux_actions {
    display: block;
    margin: -10px 50px;
  }
  .ui_ux_view_more_btn {
    padding: 12px 0px;
    width: 300px;
    margin-bottom: 10px;
  }
  .ui_ux_c_more_btn {
    width: 300px;
  }
  .ui_c_btn {
    margin-top: 20px;
    margin-left: 55px;
  }
}
