.wordpressportfolio_w{
  width: 100%;
  height: 100vh;
}
.internal_scrolling_w{
  width: 75%;
  height: 90vh;
  overflow-y: scroll;
}

.internal_scrolling_w::-webkit-scrollbar {
  width: 5px;
  max-width: 5px;
 }
  
  .internal_scrolling_w::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px
  }
  
  .internal_scrolling_w::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 

  }
  .internal_scrolling_w::-webkit-scrollbar-thumb:window-inactive {
}
.top {
  display: flex;
  align-items: center;
  margin: auto 100px;
  margin-top: 30px;
}
.left {
  display: flex;
  align-items: center;
}
.left p {
  color: #fff;
  font-size: 17px;
  font-weight: 300;
  font-family: 'Raleway', sans-serif;

}
.left h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 300;
  font-family: 'Raleway', sans-serif;
}
.c_btn {
  display: block;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 250px;
  border-radius: 50px;
  background-color: #167a3c;
  text-align: center;
  margin-left: 100px;
  cursor: pointer;
  transition: 0.4s;
  margin-left: 90px;
  font-family: 'Raleway', sans-serif;
}
.c_btn:hover {
  background-color: #1a8d46;
}
.w_main {
  display: flex;
}
.w__img {
  width: 100%;
  height: 100vh;
}
.right_info {
  width: 20%;
  margin: 80px 0 0 50px;
}
.right_info h1 {
  color: #fff;
  font-size: 40px;
  font-family: 'Raleway', sans-serif;
}

.right_info p {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
  text-align: left;
  font-family: 'Raleway', sans-serif;


}
/* model styling */
#modal-modal-title{
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 20px;
}
.model_main{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  padding: 27px;
  box-shadow: 24;
}
.field{
  width: 294px;
}

@media (max-width: 600px) {
  /* model styling */
  .model_main{
    width: 350px;
  }
  .field{
    width: 350px;
  }
  .top {
    display: block;
    align-items: center;
    margin: auto 100px;
    margin-top: -30px;
  }
  .left {
    margin-top: 50px;
  }
  .left p {
    font-size: 24px;
    font-weight: 300;
    margin-right: 10px;
  }
  .left h2 {
    font-size: 25px;
    font-weight: 900;
  }
  .c_btn {
    width: 250px;
    margin-top: 20px;
    font-size: 18px;
    margin-left: 55px;
  }
  .w_main {
    display: block;
  }
  .right_info {
    width: 160%;
    margin-left: -190px;
    margin-bottom: 100px;
  }
  .right_info h1 {
    color: #fff;
    text-align: justify;
    font-size: 60px;
  }

  .right_info p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: justify;
  }
  .right_info span {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    text-align: justify;
  }
  .internal_scrolling_w{
    width: 185%;
    height: 79vh;
    margin-left: -190px;
    overflow-y: scroll;
  }
}
