.branding_c_btn {
  display: block;
  font-size: 19px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 240px;
  border-radius: 50px;
  background-color: #bd3e42;
  margin-left: 100px;
  cursor: pointer;
  transition: 0.4s;
  list-style: none;
  text-align: center;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
}

.tops{
  display: flex;
  margin-top: 20px;
  margin-left: 30px;
}

.m_property{
  margin-right: 100px;
}

.branding_c_btn:hover {
  background-color: #c8484c;
}

.w_icon_branding {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}

.w_icon_branding:hover {
  background-color: #bd3e42;
  border: 2px solid #bd3e42;
}

.w_icon_branding2 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;

}
.w_icon_branding2:hover {
  background-color: #bd3e42;
  border: 2px solid #bd3e42;
}

.w_icon_branding3 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}

.w_icon_branding3:hover {
  background-color: #bd3e42;
  border: 2px solid #bd3e42;
}

.w_icon_branding4 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}

.w_icon_branding4:hover {
  background-color: #bd3e42;
  border: 2px solid #bd3e42;
}

.w_icon_branding5 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}

.w_icon_branding5:hover {
  background-color: #bd3e42;
  border: 2px solid #bd3e42;
}

.w_icon_branding6 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
}

.w_icon_branding6:hover {
  background-color: #bd3e42;
  border: 2px solid #bd3e42;
}

/* first block */
.w_icon_branding::before,
.w_icon_branding::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 9%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.w_icon_branding::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #f04f54;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
  font-family: 'Raleway', sans-serif;
}

.w_icon_branding:hover::before,
.w_img:hover::after {
  --scale: 1;
}

.w_icon_branding::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

/* second block */
.w_icon_branding2::before,
.w_icon_branding2::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 24%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.w_icon_branding2::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #f04f54;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
  font-family: 'Raleway', sans-serif;
}

.w_icon_branding2:hover::before,
.w_img2:hover::after {
  --scale: 1;
}

.w_icon_branding2::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

/* third block */
.w_icon_branding3::before,
.w_icon_branding3::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 40%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.w_icon_branding3::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #f04f54;
  width: 300px;
  width: max-content;
  font-family: 'Raleway', sans-serif;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_branding3:hover::before,
.w_img3:hover::after {
  --scale: 1;
}

.w_icon_branding3::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

/* forth block */
.w_icon_branding4::before,
.w_icon_branding4::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 57%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.w_icon_branding4::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #f04f54;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_branding4:hover::before,
.w_img4:hover::after {
  --scale: 1;
}
.w_icon_branding4::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

/* fifth block */
.w_icon_branding5::before,
.w_icon_branding5::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 75%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.w_icon_branding5::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #f04f54;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
  font-family: 'Raleway', sans-serif;
}

.w_icon_branding5:hover::before,
.w_img5:hover::after {
  --scale: 1;
}

.w_icon_branding5::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

/* sixth block */
.w_icon_branding6::before,
.w_icon_branding6::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 90%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.w_icon_branding6::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #f04f54;
  width: 300px;
  width: max-content;
  padding: 8px;
  font-family: 'Raleway', sans-serif;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon_branding6:hover::before,
.w_img6:hover::after {
  --scale: 1;
}

.w_icon_branding6::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

.view_more_btn_branding {
  display: block;
  margin: 0 5px;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  border: 1px solid #fff;
  width: 200px;
  border-radius: 50px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.view_more_btn_branding:hover {
  background-color: #fff;
  color: #f04f54;
}

.c_more_btn_branding {
  display: block;
  margin: 0 5px;
  font-size: 13px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 200px;
  border-radius: 50px;
  text-align: center;
  background-color: #bd3e42;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  transition: 0.3s ;
}

.c_more_btn_branding:hover {
  background-color: #cc464b;
}

@media(max-width: 600px){
  .branding_c_btn {
    width: 240px;
    margin-left: 55px;
    margin-top: 20px;
  }

  .view_more_btn_branding {
    width: 100%;
  }

  .c_more_btn_branding {
    width: 100%;
    margin-top: 15px;
  }

 .tops{
   display: block;
   margin-top: 100px;
   margin-left: 30px;
 }

}