.bg-color {
  background-image: linear-gradient(
    to right,
    rgb(14, 141, 178),
    rgb(96, 167, 51)
  );
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

.c_title {
  text-align: center;
  color: #fff;
  font-size: 45px;
  font-weight: 300;
  padding-top: 50px;
  font-family: 'Raleway', sans-serif;
}

.contact-form {
  padding: 30px 0px;
}

.contact-form-inner {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.contact-form-inner span {
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
}

.contact-form-inner p {
  color: #fff;
  font-size: 17px;
  font-weight: 300;
  font-family: 'Raleway', sans-serif;
  margin-top: 5px;
}

.c_form {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row_input {
  display: flex;
}

.c_input {
  width: 100%;
  float: left;
  background: #d2e9ea;
  border: unset;
  margin-bottom: 30px;
  padding: 14px 20px;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;
  min-height: auto;
  margin: 0;
  outline: none;
  margin-bottom: 20px;
}

.c_input2 {
  width: 288px;
  background: #d2e9ea;
  border: unset;
  margin-bottom: 30px;
  padding: 14px 20px;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;
  min-height: auto;
  margin: 0;
  outline: none;
  margin-bottom: 20px;
  margin-right: 20px;
}

.c_input3 {
  width: 328px;
  background: #d2e9ea;
  border: unset;
  margin-bottom: 30px;
  padding: 14px 20px;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;
  min-height: auto;
  margin: 0;
  outline: none;
  margin-bottom: 20px;
  margin-right: 20px;
}

.budget {
  width: 328px;
  background: #d2e9ea;
  border: unset;
  margin-bottom: 30px;
  padding: 14px 20px;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;
  min-height: auto;
  margin: 0;
  outline: none;
  margin-right: 20px;
  margin-bottom: 20px;
}

.b_option {
  width: 340px;
  background: #d2e9ea;
  border: unset;
  margin-bottom: 30px;
  padding: 14px 20px;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  min-height: auto;
  margin: 0;
  outline: none;
  margin-bottom: 20px;
}

.c_form .q-btn__wrapper {
  min-height: auto;
}

.c_form .select::before {
  position: absolute;
  content: "\f35d";
  height: 99%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  background: #d2e9ea;
  border-radius: 5px;
  color: #999;
  top: 0;
  pointer-events: none;
  font: normal normal normal 35px/1 "Material Design Icons";
}

.c_form.q-form .q-field:first-of-type {
  width: 100%;
}

.c_form.q-form .q-field.q-textarea {
  width: 100%;
}

.q-field.row.no-wrap.items-start.q-select.q-field--auto-height.q-select--without-input.q-field--filled.q-field--labeled {
  background: #d2e9ea;
  border-radius: 5px;
}

.q-field__native.row.items-center span {
  font-size: unset;
  color: unset;
}

.c_form.q-form .q-field {
  width: 48%;
  margin-bottom: 30px;
  padding: 0;
}

.overlay-top[data-v-4c22b934] {
  background: #f04f54 !important;
  transition-duration: 1s !important;
}

.overlay-left[data-v-4c22b934] {
  background: #1fae55 !important;
  transition-duration: 1s !important;
}

.overlay-right[data-v-4c22b934] {
  background: #03a5db !important;
  transition-duration: 1s !important;
}

.q-field.row.no-wrap.items-start.q-select {
  padding: 0;
}

.more-s-client{
  display: block;
  margin: 0 auto;
  margin-bottom: 70px;
  margin-top: 50px;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  border: 2px solid #fff;
  width: 360px;
  border-radius: 50px;
  transition: 0.3s linear;
  font-family: 'Raleway', sans-serif;
}

.more-s-client:hover {
  color: #1fae55;
  background: #fff;
}

.c_close {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  font-size: 20px;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 30px;
  line-height: 40px;
  text-align: center;
  color: #ffff;
}

@media (max-width: 600px) {
  .c_title {
    text-align: center;
    color: #fff;
    font-size: 40px;
    padding-top: 90px;
  }

  .contact-form-inner {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }

  .row_input {
    display: block;
  }

  .c_input {
    width: 288px;
    margin: auto;
    padding: 14px 20px;
    margin-bottom: 20px;
    margin-left: 13px;
  }
  
}
