.service-content {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.serviceslide{
    
}
.slide-close-button img {
  filter: invert(100%) sepia(0%) saturate(3158%) hue-rotate(86deg)
    brightness(105%) contrast(100%);
}

.service-img {
  border: 1px solid #fff;
  padding: 50px;
  border-radius: 50%;
  width: 200px;
  max-width: 200px;
  height: 200px;
  max-height: 200px;
}
.service-detail {
  width: 50%;
  position: relative;
}

.service-detail h2 {
  color: #fff;
  font-size: 37px;
  margin-left: 30px;
  text-align: left;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
}
.service-detail p {
  color: #fff;
  font-size: 17px;
  margin-top: 10px;
  font-weight: 300;
  margin-left: 30px;
  text-align: justify;
  font-family: 'Lato', sans-serif;
}
.service_actions {
  display: flex;
  align-items: center;
  margin: 0 25px;
}
.view_more_btn {
  display: block;
  margin: 0 5px;
  font-size: 17px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  border: 1px solid #fff;
  width: 230px;
  border-radius: 50px;
  font-family: 'Museo', sans-serif;
  font-weight: 500;
}
.view_more_btn:hover {
  color: #1fae55;
  background: #fff;
}
.c_more_btn {
  display: block;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 12px 0px;
  width: 260px;
  border-radius: 50px;
  background-color: #167a3c;
  cursor: pointer;
  font-family: 'Museo', sans-serif;
  font-weight: 500;
}
.c_icon {
  display: flex;
  position: absolute;
  top: 240px;
  left: 50px;
}
/*
.w_icon {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
  font-family: 'Raleway', sans-serif;
} */
.w_icon {
  margin-top: 3px;
  text-align: center;
}
.w_icon2 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
  font-family: 'Raleway', sans-serif;
}
.w_icon3 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
  font-family: 'Raleway', sans-serif;
}
.w_icon4 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
  font-family: 'Raleway', sans-serif;
}
.w_icon5 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
  font-family: 'Raleway', sans-serif;
}
.w_icon6 {
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  transition: 0.3s;
  font-family: 'Raleway', sans-serif;
}
.w_icon:hover {
 
}
.w_icon2:hover {
  background-color: #167a3c;
  border: 2px solid #167a3c;
}
.w_icon3:hover {
  background-color: #167a3c;
  border: 2px solid #167a3c;
}
.w_icon4:hover {
  background-color: #167a3c;
  border: 2px solid #167a3c;
}
.w_icon5:hover {
  background-color: #167a3c;
  border: 2px solid #167a3c;
}
.w_icon6:hover {
  background-color: #167a3c;
  border: 2px solid #167a3c;
}
.w_img {
  width: 30px;
  max-width: 30px;
  height: 30px;
  max-height: 30px;
}
/* first block */
.w_icon::before,
.w_icon::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 9%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #1fae55;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon:hover::before,
.w_img:hover::after {
  --scale: 1;
}
.w_icon::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* second block */
.w_icon2::before,
.w_icon2::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 24%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon2::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #1fae55;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon2:hover::before,
.w_img2:hover::after {
  --scale: 1;
}
.w_icon2::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* third block */
.w_icon3::before,
.w_icon3::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 40%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon3::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #1fae55;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon3:hover::before,
.w_img3:hover::after {
  --scale: 1;
}
.w_icon3::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* forth block */
.w_icon4::before,
.w_icon4::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 57%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon4::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #1fae55;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon4:hover::before,
.w_img4:hover::after {
  --scale: 1;
}
.w_icon4::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}

/* fifth block */
.w_icon5::before,
.w_icon5::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 75%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon5::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #1fae55;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon5:hover::before,
.w_img5:hover::after {
  --scale: 1;
}
.w_icon5::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
/* sixth block */
.w_icon6::before,
.w_icon6::after {
  --scale: 0;
  --arrow-size: 10px;
  --tooltip-color: #fff;
  position: absolute;
  top: -0.25rem;
  left: 90%;
  transform: translateX(-50%) translateY(var(--translate-y, 0))
    scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}


.w_icon6::before {
  --translate-y: calc(-100% - var(--arrow-size));
  content: attr(data-tooltip);
  color: #1fae55;
  width: 300px;
  width: max-content;
  padding: 8px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background: var(--tooltip-color);
  line-height: 30px;
}

.w_icon6:hover::before,
.w_img6:hover::after {
  --scale: 1;
}
.w_icon6::after {
  --translate-y: calc(-1 * var(--arrow-size));
  content: "";
  border: var(--arrow-size) solid transparent;
  border-top-color: var(--tooltip-color);
  transform-origin: top center;
}
.close_btn{
  position: absolute;
  top: 10px ;
  right: 30px;
}
.menu_btn{
  position: absolute;
  top: 10px ;
  left: 10px;
  text-decoration: none;
}
.bar {
  color: #ffff; 
}

.arrow_none{
  display: none;
}
.carousel .thumbs-wrapper{
    text-align: center;
}
.carousel .thumb{
    border: 3px solid #fff;
    border-radius: 50%;
    width: 60px !important;
    height: 60px !important;
    padding: 10px;
    opacity:0.6;
}
.carousel .thumb.selected,
.carousel .thumb:hover{
    opacity:1;
}
@media (max-width: 600px) {
  .arrow_none{
    display: block;
    margin-right: 5px;
  }
  .display_flex{
    display: flex;
    padding: 0px 60px;
  }
  .service-content {
    width: 100%;
    height: 80vh;
    margin: auto -0px;
    display: block;
  }
  .service-img {
    width: 50%;
    margin: 90px 90px;
  }
  .service-detail {
    width: 90%;
    margin-top: 30px;
  }
  .service-detail h2 {
    font-size: 20px;
    font-weight: 600;
  }
  .service-detail p {
    font-size: 16px;
  }
  .service_actions {
    display: block;
    margin: -10px 50px;
  }
  .view_more_btn {
    padding: 12px 0px;
    width: 300px;
    margin-bottom: 10px;
  }
  .c_more_btn {
    width: 300px;
  }
  .c_icon {
    display: none;
  }
}
